import { Box, ButtonProps, Text, TextProps } from "@chakra-ui/react";
import { secondaryColorTwo } from "../config";
import { PropsWithChildren } from "react";


export function ItalicText({ children, ...props }: PropsWithChildren<ButtonProps>) {
    return (<Box as='i' {...props}>
        {children}
    </Box>)
}

export function Author({ children, ...props }: PropsWithChildren<TextProps>) {
    return (<Text {...props} color={secondaryColorTwo} textAlign={"right"}>~~~ {children}</Text>)
}