import { Box } from "@chakra-ui/react";
import IEExperience from "../components/IEExperience";
import NavigatableIntroCard from "../components/NavigatableIntroCard";
import NonStickyNavAndFooter from "./layout/NonStickyNavAndFooter";
import { headingPrimaryFont, headingSecondaryFont, maxWidth, primaryFontSize, secondaryColorTwo } from "../config";
import { TeamMembersIntro } from "../data/data";
import ElevatedCardCarousal from "../components/Carousels/ElevatedSideImageCardCarousal";

export default function TeamMembers() {
    return (
        <NonStickyNavAndFooter>
            <Box >
                <Box
                    maxW={{ base: "auto", lg: maxWidth }}
                    as="a"
                    href="https://jkrishnamurti.org/content/series-i-chapter-37-idea-and-fact"
                    title="Difference?"
                    target='_blank'
                    m={headingSecondaryFont}
                    mb={0}
                    fontSize={{ base: primaryFontSize, md: headingPrimaryFont }} color={secondaryColorTwo}>
                    The Family of Swadharma Spandana

                </Box>
                <Box
                    display={{ base: "block", "lg": "flex" }}
                    flexDir={{ base: "column", lg: "row" }}
                    justifyContent={"space-evenly"} mt={0}>
                    <ElevatedCardCarousal elevatedCards={TeamMembersIntro} />
                </Box>


            </Box>
        </NonStickyNavAndFooter>
    )
}

