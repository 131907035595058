import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import { schoolPageIntroduction } from "../data/data";
import blossomintoinnerfreedom from '../resources/images/blossomintoinnerfreedom.png'

export default function HomeSchool() {
    return (
        <StickyNavAndFooter>
            <MainImageCarousel />
            <IntroductionWithImage img={blossomintoinnerfreedom}> 
                {schoolPageIntroduction}
            </IntroductionWithImage>
            {/* <CardCarousalContainer cards={visionCards} containerTitle={"Vision"}/>
            <br />
            <br />
            <CardCarousalContainer cards={missionCards} containerTitle="Mission"/> */}
        </StickyNavAndFooter>
    )
}