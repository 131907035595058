import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import { libraryPageIntroduction, } from "../data/data";
import { Heading } from "@chakra-ui/react";
import blossomintoinnerfreedom from '../resources/images/blossomintoinnerfreedom.png'

export default function SoftwareSystems() {
    return (
        <StickyNavAndFooter>
            <MainImageCarousel />
            <IntroductionWithImage img={blossomintoinnerfreedom}> 
                {libraryPageIntroduction}
            </IntroductionWithImage>
            <Heading>Introduction</Heading>
            {/* <CardCarousalContainer cards={visionCards} containerTitle={"Vision"}/>
            <br />
            <br />
            <CardCarousalContainer cards={missionCards} containerTitle="Mission"/> */}
        </StickyNavAndFooter>
    )
}