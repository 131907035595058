import { Box } from "@chakra-ui/react";
import ElevatedSideImageCard, { ElevatedSideImageCardProps_t } from "./ElevatedSideImageCard/ElevatedSideImageCard";
import { useNavigate } from "react-router-dom";

export interface NavigatableIntroCardProps_t {
    card: ElevatedSideImageCardProps_t
    href: string
}

export default function NavigatableIntroCard({ card, href  }: NavigatableIntroCardProps_t) {
    const navigate = useNavigate();
    return (
        <Box >
            <Box maxW={"90rem"} pl={{ "3xl": "20rem" }} key={card.title}>
                <ElevatedSideImageCard img={card.img} title={card.title} subtitle={card.subtitle}
                    imgAlt={card.imgAlt} hoverTitle={card.hoverTitle}
                    shortContent={card.shortContent} content={card.content}
                    className="elevated-card" onClick={() => {
                        navigate(href)
                    }} />
            </Box>

        </Box>)
}
