import { Box, Text } from "@chakra-ui/react";
import Carousel from "./Carousel";
import { useBreakpointValue } from '@chakra-ui/react'
import { Settings } from "react-slick";
import { bodyBGColor, headingPrimaryFont, primaryColor, secondaryColorTwo, secondaryFontSize } from "../../config";
import { CarouselcontainerStyles_t } from "./carousalTypes";
import DividerAbsoluteCenter from "../DividerAbsoluteCenter";
import ElevatedSideImageCard, { ElevatedSideImageCardProps_t } from "../ElevatedSideImageCard/ElevatedSideImageCard";
import NavigatableIntroCard, { NavigatableIntroCardProps_t } from "../NavigatableIntroCard";

export interface CardCarousalProps_t {
    elevatedCards: ElevatedSideImageCardProps_t[] | NavigatableIntroCardProps_t[];
    // imageHeight: string;
    containerTitle?: string;
    subTitle?: string;
    carouselSettings?: Settings;
}

export default function ElevatedCardCarousal({ elevatedCards, containerTitle, subTitle,
    carouselSettings = {
        fade: false,
        slidesToShow: elevatedCards.length > 2 ? 3 : elevatedCards.length,
        slidesToScroll: 1,
        arrows: elevatedCards.length > 2,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: elevatedCards.length > 2,
                    autoplay: elevatedCards.length > 2 ,
                    autoplaySpeed: 2000,
                    dots: elevatedCards.length > 2 ,
                }
            },
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: elevatedCards.length > 2 ? 2 : elevatedCards.length,
                    slidesToScroll: 1,
                    infinite: elevatedCards.length > 2,
                    autoplay: elevatedCards.length > 1 ,
                    autoplaySpeed: 2000,
                    dots: elevatedCards.length > 2 ,
                }
            },
            {
                breakpoint: 1320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: elevatedCards.length > 1,
                    dots: elevatedCards.length > 1 ,
                    autoplay: elevatedCards.length > 2,
                    autoplaySpeed: 1000,
                    arrows: elevatedCards.length > 1,
                }
            },
        ]
    } }: CardCarousalProps_t) {
    const carouselItems = elevatedCards.map(card => {
        const href = (card as NavigatableIntroCardProps_t).href
        if ( href !== undefined && href !==  "") {
            card = (card as NavigatableIntroCardProps_t).card
            return (
                <Box maxW={"90rem"} pl={{ "3xl": "20rem" }} key={card.title} pt={{ base: "5rem"} }>
                    <NavigatableIntroCard card={card} href={href} />
                </Box>
            )
        }
        if (href ===  "") {
            card = (card as NavigatableIntroCardProps_t).card
        }
        card = (card as ElevatedSideImageCardProps_t)
        return (
            <Box maxW={"90rem"} pl={{ "3xl": "20rem" }} key={card.title} pt={{ base: "5rem"} }>
                <ElevatedSideImageCard img={card.img} title={card.title} subtitle={card.subtitle}
                    imgAlt={card.imgAlt} hoverTitle={card.hoverTitle}
                    shortContent={card.shortContent} content={card.content}
                    modelContent={card.modelContent ? card.modelContent : undefined}
                    className="elevated-card" />
            </Box>
        )
    })

    const arrows = useBreakpointValue({ ssr: false, base: true, lg: false })
    if (!carouselSettings.arrows) {
        carouselSettings.arrows = arrows
    }

    const carouselContainerStyle: CarouselcontainerStyles_t = {
        position: useBreakpointValue({ base: "relative" })!,
        height: useBreakpointValue({ base: "auto" })!,
        minHeight: useBreakpointValue({ base: "auto" })!,
        maxHeight: useBreakpointValue({ base: "auto" })!,
        width: useBreakpointValue({ base: "90vw" })!,
        overflow: 'scroll',
        zIndex: 0,
        slider_height: "35rem"
    }

    return (
        <Box
            maxW={{ base: "100vW", "lg": "95rem" }}
            h={"auto"}
        >
            {containerTitle &&
                (<DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor}>
                    <Text fontSize={headingPrimaryFont} color={primaryColor}>{containerTitle}</Text>
                </DividerAbsoluteCenter>)
            }
            {
                subTitle && <Box textAlign={"center"}>
                    <Text fontSize={secondaryFontSize} color={secondaryColorTwo}>{subTitle}</Text>
                </Box>
            }
            
            <Carousel cards={carouselItems} carouselSettings={carouselSettings}
                sliderArrowLeftTop={useBreakpointValue({ base: "60%", md: "8%" })}
                sliderArrowLeftSide={useBreakpointValue({ base: "", md: "80%", "2xl": "70rem" })}
                sliderArrowRightTop={useBreakpointValue({ base: "60%", md: "8%" })}
                sliderArrowRightSide={useBreakpointValue({ base: "", md: "8%", "2xl": "10rem" })}
                containerStyles={carouselContainerStyle} cauouselContainerClassName="eleveated-card"
            />
            {/* {carouselItems} */}
        </Box>)
}