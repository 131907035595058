import { Box, Card, CardBody, Image, useBreakpointValue } from "@chakra-ui/react";
import { headingPrimaryFont, headingSecondaryFont, primaryFontSize } from "../config";
import { PropsWithChildren } from "react";

interface IntroductionWithImage_t {
    img: any
    imgAlt?: string
    id?: string
    className?: string
    invert?: boolean
}

export default function IntroductionWithImage({ img, children, id,
    invert = false,
    className = "" }: PropsWithChildren<IntroductionWithImage_t>) {
    return (
        <Box
            id={id}
            mt={{ base: 0, lg: primaryFontSize }}
            ml={headingSecondaryFont}
            mr={{ base: headingSecondaryFont, lg: primaryFontSize }}
            mb={{ base: headingSecondaryFont, lg: headingPrimaryFont }}
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
            flexDirection={{ base: "column", lg: "row" }}
            className={className + "_intro-with-image-container"}
            alignContent={"space-evenly"}
        >
            {invert ?
                <>
                    <Box className="childrent">
                        {children}
                    </Box>
                    <Box
                        width={{ base: "100%", lg: "40vw" }}
                        height={{ base: "auto", md: "30rem" }}
                        minH={{ base: "25rem", lg: "auto" }}
                        minW={{ base: "100%", lg: "10rem" }}
                        display={"flex"}
                        backgroundImage={img}
                        backgroundSize="contain"
                        bgRepeat={"no-repeat"}
                        justifyContent={"center"}
                        bgPos={"50%"}
                        className="bg-container"
                    />
                </> :
                <>
                    <Box
                        width={{ base: "100%", lg: "40vw" }}
                        height={{ base: "20rem", md: "30rem" }}
                        
                        minW={{ base: "100%", lg: "10rem" }}
                        display={"flex"}
                        backgroundImage={img}
                        backgroundSize="contain"
                        bgRepeat={"no-repeat"}
                        justifyContent={"center"}
                        bgPos={"50%"}
                        className="bg-container"
                    />
                    <Box className="childrent">
                        {children}
                    </Box>
                </>}

        </Box>)
}
