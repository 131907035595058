import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles.css";
import Home from "./pages/Home";
import About from "./pages/About";
import BlogPage from "./pages/BlogPage";
import HomeSchool from "./pages/HomeSchool";
import Library from "./pages/Library";
import SoftwareSystems from "./pages/SoftwareSystems";
import ExperiencesPage from "./pages/ExperiencesPage";
import GenericLearning from "./pages/GenericLearning";
import PDSupport from "./pages/PDSupport";
import TeamMembers from "./pages/TeamMembers";


const router = createBrowserRouter([
  {'path': '/', element: <Home />},
  {'path': '/home', element: <Home />},
  {'path': '/blog/team-members', element: <TeamMembers />},
  {'path': '/blog/sharath', element: <About />},
  {'path': '/blog', element: <BlogPage />},
  {'path': '/homeschool', element: <HomeSchool />},
  {'path': '/learn', element: <GenericLearning />},
  {'path': '/learn/pd-support', element: <PDSupport />},
  {'path': '/learn/library', element: <Library />},
  {'path': '/softwaresystems', element: <SoftwareSystems />},  
  {'path': '/blog/our-experiences', element: <ExperiencesPage />},
  {'path': '/blog/way-of-life', element: <ExperiencesPage />},
  {'path': '/initiatives', element: <Home />}  
])

export default function App() {
  return (<RouterProvider router={router}/>
  );
}
