
import { Box, Text, Link, Heading, Tooltip, Flex } from "@chakra-ui/react"
import { BiLogoFacebookCircle, BiLogoGmail, BiLogoInstagram } from "react-icons/bi"
import { ImLocation } from "react-icons/im";
import { headingSecondaryFont, headingTertiaryFont, maxWidth, primaryColor, secondaryColorOne } from "../../config";
import { PhoneIcon } from "@chakra-ui/icons";




export default function ResponsiveFooter() {

    const footerExplore = (
        <Box className="footer__explore"
            padding={'1rem'}
        >
            <Heading fontSize={headingSecondaryFont} as={'b'} > Explore Our</Heading>
            <br />
            <br />
            <Flex direction="column" color={secondaryColorOne}>
                <Flex justifyContent={'space-between'}>
                    <Box as='a' href='/#vision' >Vision  </Box>
                    <Box as='a' href='/#mission' > Mission  </Box>
                </Flex>

                <Flex justifyContent={'space-between'}>
                    <Box as='a' href='/learn'
                        display={'flex'} flexDir={'column'}>
                        <Box >
                            Learning
                        </Box>
                        Oppurtunities
                    </Box>
                    <Box as='a' href='/library' >Library  </Box>
                    {/* <ListItem ><Box ml={"1rem"} as='a' href='/our-experiences' > EXPERIENCES  </Box></ListItem>
                <ListItem ><Box ml={"1rem"} as='a' href='/blog' > BLOG  </Box></ListItem> */}
                </Flex>
            </Flex>
        </Box>
    )

    const footerAboutUs = (
        <Box className="footer__aboutus" padding={'1rem'}>
            <Heading as={'a'} href="/"> About US</Heading>
            <br />
            <Text>A Community prioritizing human wellbeing in its truest essence.</Text>
            <br />
            <Box className="footer__find-us">
                <Box fontSize={headingTertiaryFont}
                    fontWeight={'bold'}
                    as={'a'} href="/blog" // blog should have its own page
                >Blog</Box>
                <br />
                <Text wordBreak={"break-word"} overflowWrap={"break-word"} maxW={"30rem"}>
                    We realise the power of words when directed with the intent,
                    <Box color={secondaryColorOne}
                        fontWeight={'bold'}
                        as={'a'} href="/blog"> read about us!</Box></Text>


                <Box className="footer__find-us--social_icon"
                    display={{ base: 'flex' }}
                    flexDirection={{ md: 'row' }}
                    height={'auto'}
                    fontSize={'x-large'}
                    alignItems={'center'}
                    justifyItems={"center"}
                    pt={"0.2rem"}
                >
                    <Link href='https://www.facebook.com/' target="_blank" pr={"0.5rem"}><BiLogoFacebookCircle /></Link>
                    <Box pr={"0.5rem"}>|</Box>
                    <Link href='https://www.instagram.com/' target="_blank"><BiLogoInstagram /></Link>
                </Box>
            </Box>
        </Box>
    )

    const contactUs = (
        <Box p={'1rem'} id='contactUs'>
            <Heading fontSize={headingSecondaryFont}>Let's Meet & Mingle</Heading>
            <br />
            <Box display={"flex"} >
                <Box alignContent={"center"}>
                    <ImLocation />
                </Box>
                <Tooltip hasArrow
                    label={`near Karnataka bank HMT Layout Bengaluru 560073, Karnataka`} >
                    <Box>
                        <Box display={{ base: 'none', md: 'block' }}
                            as="a" href='https://maps.app.goo.gl/zP2EjdVD1PYP3NEq8'
                            target="_blank" color={secondaryColorOne} fontWeight={'bold'}>
                            #92 Spandanalayam 6th A cross Suvarna nagar,
                        </Box>
                        <Box display={{ base: 'block', md: 'none' }}
                            as="a" href='https://maps.app.goo.gl/zP2EjdVD1PYP3NEq8'
                            target="_blank" color={secondaryColorOne} fontWeight={'bold'}>
                            #92 Spandanalayam 6th A cross Suvarna nagar,
                            <br />
                            near Karnataka bank HMT Layout
                            <br />
                            Bengaluru 560073, Karnataka
                        </Box>
                    </Box>
                </Tooltip>
            </Box>
            <br />
            <Text><PhoneIcon /> +91 9986216387 </Text>
            <Box display={"flex"} >
                <Box alignContent={"center"} pr="0.5rem">
                    <BiLogoGmail />
                </Box>
                <Text>  swadharmaspandana@gmail.com </Text>
            </Box>

        </Box>)

    const siteCopyRightTerms = (<Box className='site__copyright-terms'
        display={{ sm: 'flex' }}
        padding={'1rem'}
        pl={0}
        justifyContent={'space-between'}
        height={'auto'}
        textAlign={'justify'}
        fontSize={{ base: '1rem' }} >
        <Text>2024 &copy; All Rights Reserved | <Link href="#">Terms of Service</Link>
        </Text>
    </Box>

    )

    return (
        <>
            <Box className="nav-bar" position={"absolute"} minHeight={"5rem"}
                height={'18.5rem'} w="100vw" />
            <Box display={"flex"} justifyContent={"center"} position={"relative"}>
                <Box display={{ lg: 'Flex' }}
                    flexDirection={{ md: 'row' }}
                    backgroundColor={primaryColor}
                    justifyContent={'space-around'}
                    color={'whitesmoke'}
                    height={'auto'}
                    width={{ base: "100vW" }}
                    wordBreak={'break-word'}
                    textAlign={'justify'}
                    padding={'1.5rem'}
                    paddingX={{ '2xl': '10rem' }}
                    maxW={maxWidth}
                >
                    {footerAboutUs}
                    <hr style={{ color: 'coral' }} />
                    {footerExplore}
                    <hr style={{ color: 'coral' }} />
                    {contactUs}

                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} >
                <Box display={"flex"} alignItems={"flex-start"} w={maxWidth}>
                    {siteCopyRightTerms}
                </Box>
            </Box>
        </>
    )
}