import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import {
    professionDevelopmentPageIntroduction, libraryWeAcceptBooks,
    professionLevel1, professionLevel2,
    professionLevel3
} from "../data/data";
import { Box, Card, CardBody, CardFooter, CardHeader, Text, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import profession from '../resources/images/profession.jpeg'
import { bodyBGColor, headingPrimaryFont, headingSecondaryFont, headingTertiaryFont, primaryColor, primaryFontSize, secondaryColorTwo, secondaryFontSize, smallFontSize } from "../config";
import wehavebooks from '../resources/images/wehavebooks.jpg'
import mailUsBooks from '../resources/images/mailusbooks.webp'
import DividerAbsoluteCenter from "../components/DividerAbsoluteCenter";

export default function PDSupport() {


    return (
        <StickyNavAndFooter>
            <MainImageCarousel />
            <IntroductionWithImage img={profession}>
                {professionDevelopmentPageIntroduction}
                <br />
            </IntroductionWithImage>
            <br />

            <DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor}>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDir={"column"}
                >
                    <Text fontSize={{ base: primaryFontSize, md: headingSecondaryFont }}
                        color={primaryColor}
                    >
                        From Labour To A Profession!
                    </Text>
                    <Text fontSize={{ base: primaryFontSize, md: primaryFontSize }} color={secondaryColorTwo}
                        alignSelf={"center"}>
                        and beyond...
                    </Text>
                </Box>
            </DividerAbsoluteCenter>
            <br />
            <Card pl={{ base: 0, md: "4rem" }} pr={{ base: 0, md: "4rem" }} id="lets-read">
                <CardHeader p={0} pt={"2rem"} >
                    <Text fontSize={headingPrimaryFont}
                        color={primaryColor}
                        textAlign={"center"}>Professional Development Support</Text>
                    <br />
                    <Text fontSize={secondaryFontSize}
                        color={secondaryColorTwo}
                        textAlign={"justify"} >
                        In todays world where profession is understood as a way of earning things that one thinks
                        they hold highest, whithout questioning oneself why that thing has occupied the highest spot
                        in ones mental plane;
                        where profession has become synonym with mental and physical labour for the sake of something;
                        where we are not bothered to understand or contemplate what is the fact that idea of profession
                        is trying to represent;
                        <br />
                        <br />
                        We want to see profession for what it is. The root words of the profession, &quot;professio&quot;
                        which originally meant a public declaration or commitment and &quot;Fateri&quot;, this root is
                        important because it underscores the idea of a conscious commitment or acknowledgment of
                        one&apos;s chosen path or calling.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Professionalism is the abstraction or a word representating the state of deep involvment
                        begot by the unwavering commitment to the process in a direction, by exercising one&apos;s
                        will to the highest level possible. It&apos;s a way of inclusiveness, a way of life,
                        a way of being.
                    </Text>

                    <br />
                </CardHeader>
                <CardBody p={0}>
                    <Box overflow={"scroll"}>
                        <Box id={"main-introduction"}>
                            <IntroductionWithImage img={wehavebooks}
                                className="library"
                                invert={useBreakpointValue({ base: false, lg: true })!}>
                                {professionLevel1}
                            </IntroductionWithImage>
                        </Box>
                        <Box>
                            <IntroductionWithImage img={mailUsBooks}
                                className="library">
                                {professionLevel2}
                            </IntroductionWithImage>
                        </Box>
                        <Box id={"main-introduction"}>
                            <IntroductionWithImage img={wehavebooks}
                                className="library"
                                invert={useBreakpointValue({ base: false, lg: true })!}>
                                {professionLevel3}
                            </IntroductionWithImage>
                        </Box>
                    </Box>
                </CardBody>
                <CardFooter cursor={"default"}>
                    <Tooltip label="" aria-label="Contact Us for more information">
                        <Box m='auto' fontSize={headingTertiaryFont}
                            color={primaryColor}>
                            Contact Us for more information
                        </Box>
                    </Tooltip>
                </CardFooter>
            </Card>
            <br />
            <br />
        </StickyNavAndFooter>
    )
}