import { Box, Text, Card, CardBody, useBreakpointValue } from "@chakra-ui/react";
import { headingPrimaryFont, headingSecondaryFont, headingTertiaryFont, maxWidth, primaryColor, primaryFontSize, secondaryColorTwo } from "../config";
import CardCarousalContainer from "./CardCarousalContainer";
import { missionCards, visionCards } from "../data/data";
import ModalPost from "./ModalPost";
import { Author } from "./CustomUtilComponents";
import allthatiam from '../resources/images/allthatiam.jpeg'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CarouselCardDetails_t } from "./Carousels/CarouselCard";

export default function MainBlog() {
    const urlParam = new URLSearchParams(window.location.search);
    const [modelPost, setModelPost] = useState<CarouselCardDetails_t | undefined>(undefined);


    useEffect(() => {
        if (urlParam.get("id") && !modelPost) {
            const redirectedPost = [...visionCards, ...missionCards].filter(card => card.groupId + "_" + card.id == urlParam.get("id"))
            redirectedPost.length === 1 ? setModelPost(redirectedPost[0]) : setModelPost(undefined)
        }
    }, [modelPost])

    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();
    function onClose(parentId: string) {
        setIsOpen(false)
        navigate(parentId? `/blog#${parentId}` : "/blog")
    }

    function handleClick(id: string) {
        setIsOpen(true)
        const selectedPost = [...visionCards, ...missionCards].filter(card => card.groupId + "_" + card.id == id)
        setModelPost(selectedPost.length == 1 ? selectedPost[0] : undefined)
    }

    return (
        <>
            <Box ml={{ base: headingSecondaryFont, }}>
                <Text color={primaryColor} fontSize={headingSecondaryFont}
                    mt={primaryFontSize}>SwaDharma Spandana</Text>
                <Text color={secondaryColorTwo} fontSize={headingTertiaryFont} mb={primaryFontSize}>What, Why, How, Who? Know Us!</Text>
            </Box>
            <Box
                ml={headingSecondaryFont}
                mr={{ base: headingSecondaryFont, lg: primaryFontSize }}
                mb={{ base: headingSecondaryFont, lg: headingPrimaryFont }}
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
                flexDirection={{ base: "column", lg: "row" }}>

                <Box width={{ base: "100%", lg: "40vw" }}
                    height={{ base: "auto", lg: "30rem" }}
                    minH={{ base: "25rem", lg: "auto" }}
                    minW={{ base: "100%", lg: "10rem" }}
                    backgroundImage={allthatiam}
                    backgroundSize={useBreakpointValue({ base: "20rem", md: "30rem" })}
                    backgroundPosition={"50%"}
                    backgroundRepeat={"no-repeat"}>
                </Box>

                <Box
                    width={{ base: "100%", lg: "50vw" }}
                    maxW={{ base: "100%", lg: "50rem" }}
                    paddingTop={{ base: headingSecondaryFont, lg: "0" }}>
                    <Box textAlign={"center"} fontSize={headingSecondaryFont} color={primaryColor} >
                        <Box as='i'>
                            All That I Am...
                            <Text color={secondaryColorTwo} fontSize={headingTertiaryFont}>
                                I write to know,
                                <br />
                                I write to learn,
                                <br />
                                I write to surpass myself,
                                <br />
                                BeCause writings are as hallow as hell,
                                <br />
                                when written without Awareness,
                                <br />
                                Pretence in writing,
                                <br />
                                nothing short of self-deception again,
                                <br />
                                so, this is all I am,
                                <br />
                                naked in your hands...
                            </Text>
                        </Box>
                    </Box>
                    <br />
                    <Author />
                </Box>
            </Box>
            <Box id="vision">
                <CardCarousalContainer
                    footerButtonHandleClick={handleClick} cards={visionCards}
                    containerTitle={"Vision"}
                    subTitle={"BeiNg LarGer ThaN that of An IdentTitY."}
                />
            </Box>

            <Box id="mission">
                <CardCarousalContainer
                    footerButtonHandleClick={handleClick}
                    cards={missionCards} containerTitle={"Mission"} subTitle={"ThE StORy wE waNNa TeLL"} />
            </Box>
            <Box>

                {modelPost
                    && <ModalPost card={modelPost}
                        closeButtonHref={`/blog#${modelPost.groupId}`}
                        isOpen={isOpen}
                        onClose={onClose} />}
            </Box>
        </>
    )
}