import { Box, Text } from "@chakra-ui/react";
import { headingPrimaryFont, maxWidth, secondaryColorTwo, headingTertiaryFont, headingSecondaryFont, primaryFontSize, white } from "../config";
import { iEExperiences } from "../data/data";
import ElevatedCardCarousal from "./Carousels/ElevatedSideImageCardCarousal";


export default function IEExperience() {
    return (
        <Box className="ie-experience">
            <Box
                maxW={{ base: "auto", lg: maxWidth }}
                as="a"
                href="https://jkrishnamurti.org/content/series-i-chapter-37-idea-and-fact"
                title="Difference?"
                target='_blank'
                m={headingSecondaryFont}
                mb={0}
                fontSize={{ base: primaryFontSize, md: headingPrimaryFont }} color={secondaryColorTwo}>
                From Ideas to The Facts...
            </Box>
            <Box
                maxW={{ base: "auto", lg: maxWidth }}
                m={headingSecondaryFont}
                mt={0}
                display={{ base: "block", "lg": "flex" }}
                flexDir={"column"}
                bg={"#000054"}
                pb={"3rem"}
                justifyContent={"center"}
                h={"auto"}>
                <Text fontSize={{ base: headingTertiaryFont, md: headingSecondaryFont }}
                    color={white}
                    mt={primaryFontSize}
                    ml={primaryFontSize}
                >#MyInnerEngineering Experience</Text>
                <Box
                    display={{ base: "block",  }}
                    flexDir={{ base: "column", lg: "row" }}
                    h={"auto"}
                    justifyContent={"space-evenly"} className="custom-slick-dots">
                    <ElevatedCardCarousal elevatedCards={iEExperiences} />
                </Box>

            </Box>

        </Box>)
}


{/* provision to share others experience of us */ }