
import { PropsWithChildren } from 'react'
import { Box } from "@chakra-ui/react";
import { maxWidth} from "../../config";
import { NavBar } from '@sharath240697/sds-ui';
import ResponsiveFooter from '../../components/Footer/ResponsiveFooter';
import { NAV_ITEMS } from '../../data/data';


export default function NonStickyNavAndFooter({ children }: PropsWithChildren) {
    return (
        <>
            <Box className="nav-bar" position={"absolute"} minHeight={"5rem"}
                height={"10vh"} w="100vw" />
            <Box maxW={maxWidth}
                position={"relative"}
                margin={"auto"} >
                <NavBar navItems={NAV_ITEMS} sticky={false}
                    maxWidth={maxWidth} logoUrl="https://s3.ap-south-1.amazonaws.com/swadharmaspandana.org/logo.svg" />
                {children}
                <br />
                <br />
            </Box>
            <ResponsiveFooter/> 
        </>
    )
}