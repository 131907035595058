import { Box, Tooltip } from '@chakra-ui/react'
import React from 'react';
import { IconType } from 'react-icons';


export interface NavigatableIconProps_t {
    icon: IconType,
    color: string,
    href: string,
    label: string,
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    m?: string
    size?: number
}

export default function NavigatableIcon({ icon: Icon, color, href, label,
    m = '0.5rem', size = 30, onClick
}: NavigatableIconProps_t) {
    return (
        <Tooltip label={label}>
            <Box m={m}
                as='a'
                target="_blank"
                href={href}
                onClick={onClick}
            >
                <Icon size={size} style={{ color }} />
            </Box>
        </Tooltip>
    )
}