import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import {
    libraryMainBlog, genericLearningPageIntroduction,
    LearningProgramme, CulturalLearningProgramme
} from "../data/data";
import { Box, Card, CardBody, CardFooter, CardHeader, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import krishnamutrthi from '../resources/images/krishnamutrthi.jpg'
import { bodyBGColor, headingPrimaryFont, headingSecondaryFont, headingTertiaryFont, primaryColor, primaryFontSize, secondaryColorTwo, white } from "../config";
import Post from "../components/ModalPost";
import { CarouselCardDetails_t } from "../components/Carousels/CarouselCard";
import DividerAbsoluteCenter from "../components/DividerAbsoluteCenter";
import ElevatedCardCarousal from "../components/Carousels/ElevatedSideImageCardCarousal";
import ElevatedSideImageCard from "../components/ElevatedSideImageCard/ElevatedSideImageCard";
import NavigatableIntroCard from "../components/NavigatableIntroCard";

export default function GenericLearning() {
    const { isOpen, onToggle } = useDisclosure()
    const libraryMainBlogForLibPage: CarouselCardDetails_t = libraryMainBlog
    delete libraryMainBlogForLibPage.previous;
    delete libraryMainBlogForLibPage.next;
    function onClose() {
        onToggle()
    }

    return (
        <StickyNavAndFooter>
            <MainImageCarousel />
            <IntroductionWithImage img={krishnamutrthi}>
                {genericLearningPageIntroduction}
                <br />
            </IntroductionWithImage>
            <br />

            <DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor}>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDir={"column"}
                >
                    <Text fontSize={{ base: primaryFontSize, md: headingSecondaryFont }}
                        color={primaryColor}
                    >
                        IF WE WERE YOUNG WHAT WOULD OUR LIFE BE?!
                    </Text>
                    <Text fontSize={{ base: primaryFontSize, md: primaryFontSize }} color={secondaryColorTwo}
                        alignSelf={"center"}>
                        Isn&apos;t age just a number?
                    </Text>
                </Box>
            </DividerAbsoluteCenter>
            <br />

            <Card pl={{ base: 0, md: "4rem" }} pr={{ base: 0, md: "4rem" }} id="lets-read">
                <CardHeader p={0} pt={"2rem"}>
                    <Text fontSize={headingPrimaryFont}
                        color={primaryColor}
                        textAlign={"center"}>Upskilling Programmes</Text>
                </CardHeader>
                <CardBody p={0} bg={"#000054"}>
                    <Box overflow={"scroll"}>

                        <Box >
                            <Box
                                display={{ base: "block", "lg": "flex" }}
                                flexDir={{ base: "column", lg: "row" }}
                                justifyContent={"space-evenly"} className="custom-slick-dots">
                                <ElevatedCardCarousal elevatedCards={LearningProgramme} />
                            </Box>
                        </Box>
                    </Box>
                    <br />

                </CardBody>
                <CardFooter cursor={"default"}>
                    <Tooltip label="" aria-label="Contact Us for more information">
                        <Box m='auto' fontSize={headingTertiaryFont}
                            color={primaryColor}>
                            Contact Us for more information
                        </Box>
                    </Tooltip>
                </CardFooter>
                <br />
            </Card>
            <br />
            <br />
            {/* <Card pl={{ base: 0, md: "4rem" }} pr={{ base: 0, md: "4rem" }} id="lets-read">
                <CardHeader p={0} pt={"2rem"}>
                    <Text fontSize={headingPrimaryFont}
                        color={primaryColor}
                        textAlign={"center"}>Cultural Heritage</Text>
                </CardHeader>
                <CardBody p={0} bg={"#000054"}>
                    <Box overflow={"scroll"}>

                        <Box >
                            <Box
                                display={{ base: "block", "lg": "flex" }}
                                flexDir={{ base: "column", lg: "row" }}
                                justifyContent={"space-evenly"} className="custom-slick-dots">
                                <ElevatedCardCarousal elevatedCards={CulturalLearningProgramme} />
                            </Box>
                        </Box>
                    </Box>
                    <br />

                </CardBody>
                <br />
            </Card>
            {CulturalLearningProgramme.map((card, index) => {
                return (<Box maxW={"90rem"} pl={{ "3xl": "20rem" }} key={card.card.title+"_"+index} pt={{ base: "5rem" }}>
                    <NavigatableIntroCard card={card.card} href={card.href} />
                </Box>)
            })} */}
            {isOpen && <Post card={libraryMainBlogForLibPage}
                isOpen={isOpen}
                onClose={onClose} />}
        </StickyNavAndFooter>
    )
}