
import { PropsWithChildren } from 'react'
import { Box } from "@chakra-ui/react";
import { maxWidth } from "../../config";
import ResponsiveFooter from '../../components/Footer/ResponsiveFooter';
import { useTheme } from '@emotion/react';
import { NavBar } from '@sharath240697/sds-ui';
import { NAV_ITEMS } from '../../data/data';

export default function StickyNavAndFooter({ children }: PropsWithChildren) {
    const theme = useTheme()
    console.log(theme)
    return (
        <>

            <Box className="sticky nav-bar"
                position={"absolute"}
                minHeight={"5rem"}
                height={"10vh"}
                w={"100vW"}
                display={"flex"}
                justifyContent={"center"} zIndex={0}>
            </Box>
            <Box
                position={"absolute"}
                minHeight={"5rem"}
                height={"10vh"}
                w={"100vW"}
                display={"flex"}
                justifyContent={"center"}>

                <NavBar navItems={NAV_ITEMS} sticky={true}
                    maxWidth={maxWidth} logoUrl="https://s3.ap-south-1.amazonaws.com/swadharmaspandana.org/logo.svg" />
            </Box >
            <Box maxW={maxWidth}
                position={"relative"}
                margin={"auto"}>

                {children}
                <br />
                {/* <CustomCard variant="smooth" h={"35rem"} w={"30rem"}>
                    <Image
                        src='https://chakra-ui.com/eric.jpg'
                        rounded='full'
                        w={32}
                        h={32}
                        boxShadow='md'
                    />
                    <Heading mt={6} maxW={60} size='lg' textAlign='center' color='gray.700'>
                        Welcome back, Eric
                    </Heading>
                    <Text mt={6} mb={6} size='sm' color='blackAlpha.500'>
                        Use your fingerprint to continue.
                    </Text>
                    <Image src='https://upload.wikimedia.org/wikipedia/en/6/6b/Terrestrial_globe.svg' w={32} h={32} />
                </CustomCard> */}

                <br />
                <br />
            </Box>
            <ResponsiveFooter />
        </>
    )
}
